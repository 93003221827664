import { useContext, useEffect, useState } from "react";
import { isSafari } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import BrowserEmbedError from "src/components/embedTypes/BrowserEmbedError";
import ContentError from "src/components/embedTypes/ContentError";
import ContentLoading from "src/components/embedTypes/ContentLoading";
import ContentTypesRouter from "src/components/embedTypes/ContentTypesRouter";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";

const Dashboard = () => {
  const { details: workspaceDetails, menuType } = useContext(WorkspaceContext);
  const { site } = useContext(SiteContext);
  const navigate = useNavigate();
  const { workspace_id } = useParams();
  const { group, page, pfilters } = useUrlQueryParams();
  const [loadingPage, setLoadingPage] = useState(true);
  const [content, setContent] = useState(null);
  const [contentError, setContentError] = useState(null);

  const pageErrorTypes = {
    page_error: {
      title: "Reload Page",
      subtitle: "Oops... there was a loading error.",
      secondary: "Something went wrong, please try later.",
    },
    incorrect_credentials: {
      title: "Reload Page",
      subtitle: "Oops... there was a loading error.",
      secondary: "It appears the credentials that were added were invalid.",
    },
    incorrect_filters: {
      title: "Reload Page",
      subtitle: "Oops... there was a loading error.",
      secondary: "It appears the filters that were added were invalid or not filled out.",
    },
  };

  useEffect(() => {
    const handlePageGet = async () => {
      try {
        localStorage.setItem("workspace", workspace_id || "");
        localStorage.setItem("group", group || "");
        localStorage.setItem("page", page || "");

        setContentError(null);
        setLoadingPage(true);

        const { data: contentResponseData } = await apiRequest("GET", `/portal/embed/:workspace_id`, { params: { workspace_id }, queries: { group, page, pfilters } });
        let { data } = contentResponseData;

        if (data?.invalidWorkspace) {
          navigate("/permission-denied");
        } else if (data?.invalidPage || data?.invalidGroup) {
          const { data: resData } = await apiRequest("GET", `/portal/users/${workspaceDetails?._id}/page`);

          if (resData.status === 200) {
            navigate(resData.link);
            return;
          }
        } else if (data?.invalidFilter) {
          setContentError("incorrect_filters");
        } else {
          setContent(data);
          setLoadingPage(false);
        }
      } catch (error) {
        if (error.message !== "CanceledError: canceled") {
          setLoadingPage(false);
          setContentError("page_error");
        }
      }
    };

    if (workspace_id && (workspaceDetails?.workspace_type === "JWT_FULL_EMBED" || page)) {
      handlePageGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, page, workspace_id, workspaceDetails?.workspace_type, pfilters, navigate]);

  const [thirdPartyCookiesEnabled, setThirdPartyCookiesEnabled] = useState(false);
  const [thirdPartyCookiesEnabledLoaded, setThirdPartyCookiesEnabledLoaded] = useState(false);

  const [browser, setBrowser] = useState(null);

  useEffect(() => {
    const checkBrowser = () => {
      const userAgent = navigator.userAgent;
      if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
        return "Safari";
      } else if (/Chrome/.test(userAgent)) {
        return "Chrome";
      } else if (/Firefox/.test(userAgent)) {
        return "Firefox";
      } else if (/Edg/.test(userAgent)) {
        return "Edge";
      } else {
        return "Other";
      }
    };

    const checkThirdPartyCookies = () => {
      if (isSafari) {
        setThirdPartyCookiesEnabled(false);
        setThirdPartyCookiesEnabledLoaded(true);
        return;
      }
      new Promise((resolve) => {
        const iframe = document.createElement("iframe");
        iframe.src = "https://thirdparty.com"; // Use a valid external domain.
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        iframe.onload = () => {
          try {
            document.cookie = "test_third_party_cookie=1; SameSite=None; Secure";
            const cookieExists = document.cookie.indexOf("test_third_party_cookie") !== -1;
            resolve(cookieExists);
          } catch (error) {
            resolve(false);
          } finally {
            document.body.removeChild(iframe);
          }
        };
      }).then((enabled) => {
        setThirdPartyCookiesEnabled(enabled);
        setThirdPartyCookiesEnabledLoaded(true);
      });
    };

    const browserType = checkBrowser();
    setBrowser(browserType);

    checkThirdPartyCookies();
  }, []);

  return (
    <>
      {content?.config?.type === "JWT_FULL_EMBED" && thirdPartyCookiesEnabledLoaded && !thirdPartyCookiesEnabled ? (
        <BrowserEmbedError browser={browser} />
      ) : contentError ? (
        <ContentError
          pageErrorTypes={pageErrorTypes}
          pageError={contentError}
        />
      ) : (
        <>
          {loadingPage ? (
            <ContentLoading menuType={menuType} />
          ) : (
            <div className="w-full">
              <ContentTypesRouter
                content={content}
                menuType={menuType}
                workspace={workspaceDetails}
                site={site}
                setError={() => {}}
                setLoading={() => {}}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
